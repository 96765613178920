import styled from 'styled-components';

export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent || 'space-between'};
    min-height: 30vw;
    width: 35vw;
    position: absolute;
    top: -2vw;
    left: -5vw;
    background-color: white;
    padding: 2vw;
    z-index: 2;
    margin-bottom: 5vw;

    @media screen and (max-width: 1024px) {
        top: -7.5vw;

    }

    @media screen and (max-width: 770px) {
        border: 1px solid black;
        border-radius: 0%;
        left: -10vw;
        width: 45vw;
    }

    @media screen and (max-width: 767px) {
        width: 80vw;
        top: -40vh;
        padding: 5vw;
        left: -3vw;
        height: min-content;
    }
`;

export const BoldLink = styled.div`
    font-weight: 500;
    text-transform: uppercase;
    color: var(--trust);

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }
`

export const Container = styled.div`
    width: 100%;
    // display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Introduction = styled.div`
    display: flex;
    width: 100%;
    padding: 5vw 0vw;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    background-color: #f7f3ec;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-wrap: wrap;
        flex-direction: column-reverse; 
        align-items: center;
    }
`;

export const IntroductionText = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 100%;
        gap: 5vw;
        margin-bottom: 10vw;
    }
`;

export const Zwanger = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    overflowX: hidden;
    padding: 5vw 0vw;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: 1.5vw;
    justify-content: center;
    background-color: white;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        gap: 10vw 0;
    }
`;

export const HulpVoeden = styled.div`
    background-color: #F6EFE7;
    width: 100%;
    position: relative;
    
    overflowX: hidden;
    padding: 5vw 0vw;
    justify-content: center;
    
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    
    text-align: left;
    gap: 10vw;
    
    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10vw 0;
    }
`;

export const Ontdek = styled.div`
    width: 100%;
    position: relative;
    padding: 5vw 0 5vw 12vw;
    
    text-align: left;
    background-color: #FDFCFA;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 10vw 10vw;
    }
`;

export const Planning = styled.div`
    width: 100%;
    background-color: #F1E3D4;
    padding: 5vw 0;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 10vw 0;
    }
`;

export const Workshops = styled.div`
    padding: 5vw 0 5vw 12.5vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    position: relative;

    background-color: #FDFCFA;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 10vw 5vw;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 770px) {
        padding: 5vw 7vw 5vw 7vw;
    }
`

export const Kosten = styled.div`
    padding: 5vw 0 5vw 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1vw;
    position: relative;

    background-color: #F6F3EC;

    @media screen and (min-width: 1025px){
        padding: 5vw 0 5vw 20vw;
    }

    @media screen and (max-width: 766px) {
        padding: 4vw;
    }
`;

export const AanmeldenContainerText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1vw;

    @media screen and (max-width: 1024px) {
        // margin-left: 8vw;
        gap: 2vw;
    }

    @media screen and (max-width: 766px) {
        gap: 7vw;
        width: 85vw;
    }

    
`;

export const AanmeldenContainer = styled.div`
    padding: 10vw 10vw ${props => props.bottomPadding || '10vw'} 10vw;
    position: relative;
    z-index: 6;
    display: flex;
    gap: ${props => props.category === 'ouders' ? 'clamp(50px, 7vw, 200px)' : '5vw'};
    // align-items: center;
    justify-content: space-between;
    background-color: ${props => props.category === 'ouders' ? '#f1e3d4' : '#f6efe7'};
    scroll-margin-top: 20em;

    &:focus {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    }

    // @media screen and (min-width: 1025px) {
    //     justify-content: center;
    //     gap: 10vw;
    // }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        scroll-margin-top: -5em;
        flex-wrap: wrap;
        padding: 10vw;
    }

     /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 770px){
        padding: 5vw;
        gap: 10vw;
    }
`

export const Ervaringen = styled.div`
    width: 100%;
    background-color: white;

    padding: 5vw 0vw;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    text-align: center;
`

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};
`;

export const Benieuwd = styled.div`
    background-color: white;
    width: 100%;
    padding: 5vw 0vw;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
`;

export const CircleClick = styled.div`
    position: absolute;
    z-index: 5;
    left: -7vw;
    bottom: 0.5vw;
    
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 1vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: white;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 25vw;
        height: 25vw;
        bottom: -10vw;
        padding: 4vw;
    }
`;

export const CircleInfo = styled.div`
    position: absolute;
    z-index: 5;
    right: -40%;
    bottom: 15%;
    opacity: 0.9;

    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 3vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: white;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        position: absolute;
        background-color: white;

        width: 70vw;
        height: 80vw;
        top: -1vw;
        left: -1vw;
        border-radius: 0;
        opacity: 1;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 800px){
        right: -70px;
    }
`;

export const LinkClick = styled.div`
    margin: 5px 0;
    cursor: pointer;
    word-break: break-all;

    &:hover {
        font-weight: 600;
    }
`;

export const Goededoel = styled.div`
    width: 100%;

    background-color: white;
    position: relative;

    display: flex;
    align-items: flex-end;
    gap: 5vw;

    padding: 0 5vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (min-width: 1025px){
        padding: 0 10vw;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 1025px){
        padding: 5vw 10vw;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 770px){
        padding: 5vw;
    }

    
`;

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: #FDFCFA;
    width: 100px;
    height: 100px;
`;