import styled from 'styled-components';

export const Main = styled.div`
    position: relative;
    height: 100%;
`

export const Content = styled.div`
    position: relative;
    height: inherit;
    width: 100% !important;
    background-color: #f7f3ec;
    justify-content: center;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        min-height: calc(100vh - 40px - 28px);
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        min-height: calc(100vh - 75px - 50px);
    }
`;