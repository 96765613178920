import React, { useRef, useState, useEffect } from 'react';

import { Video, Typography, Sticker, Button, TitlePage, List, ListItem, PDFViewer, Image, TextToSpeech } from '../../components';
import { Container, Introduction, IntroductionText, Background, Samenwerken, WatIs, Workshops, Kosten, Kracht, Enthousiast, EnthousiastCont } from './styled';
import { Ervaringen, Mogelijk, BlogEnPodcast, Veelgestelde, Benieuwd } from '../../subpages';
import { GeneralContext } from '../../App';

// import brochure from '../../assets/sample.pdf';
import lactatiekundigen from '../../assets/images/sticker-lactatiekundigen.png';

import kracht from '../../assets/images/lactatiekundigen/kracht.jpg';

import background1 from '../../assets/backgrounds/lactatiekundigen1.png';
import background2 from '../../assets/backgrounds/lactatiekundigen2.png';

import workshop1 from '../../assets/images/workshops/workshop1.png';
import workshop2 from '../../assets/images/workshops/workshop2.png';
import workshop3 from '../../assets/images/workshops/workshop3.png';
import workshop4 from '../../assets/images/workshops/workshop4.png';
import BrochureModal from './BrochureModal';

import brochure from '../../assets/brochure_lactatiekundige.pdf';

export default function Lactatiekundigen(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ openModal, setOpenModal ] = useState(false);
    const [ backgroundProps, setBackgroundProps ] = useState({});

    const introductionRef = useRef(null);
    const benieuwdRef = useRef(null);
    const enthousiastRef = useRef(null);
    const samenwerkenRef = useRef(null);
    const watIsRef = useRef(null);
    const workshopsRef = useRef(null);
    const krachtCRef = useRef(null);

    const scrollIntoView = (ref) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        if (Object.keys(backgroundProps).length === 0 && introductionRef.current) {
            setBackgroundProps({
                top: introductionRef.current?.clientHeight,
                height: (samenwerkenRef.current?.clientHeight + watIsRef.current?.clientHeight + workshopsRef.current?.clientHeight)*1.05,
            })
        }
    }, [backgroundProps]);

    return (
        <Container>
            {!hasPhone && <Background src={background1} zIndex='3' top='0' left='0' />}
            {!hasPhone && <Background src={background2} zIndex='1' top={backgroundProps.top + 'px'} right='0' height={backgroundProps.height + 'px'} />}
            <Sticker src={lactatiekundigen} position='fixed' bottom='1vh' width='clamp(150px, 10vw, 10vw)' hover={true} onClick={() => ( scrollIntoView(benieuwdRef) )} />
            <Introduction ref={introductionRef}>
                <div style={{ zIndex: '5'}}>
                    <Video videoId='L8FoPwtlOmk' width={'45vw'} />
                </div>
                <IntroductionText>
                    <Typography type='h1' style={{ color: '#114d55', margin: '3vw 0 2vw 0', width: hasPhone ? '70vw' : 'clamp(30vw, 50vw, 470px)', fontSize: 'clamp(18px, 2.2em, 46px)'}}>Borstvoedings{hasPhone && '-'}voorbereiding<br/>om eventuele problemen<br/>altijd een stap voor te zijn</Typography>
                    <TextToSpeech />
                </IntroductionText>
            </Introduction>
            <Samenwerken ref={samenwerkenRef}>
                <Typography style={{ width: hasPhone ? '95%' : '52%'}}>Als lactatiekundige loop je vaak achter de feiten aan: je wordt pas ingeschakeld op het moment dat borstvoedingsproblemen al hun weerslag hebben gegeven op de lactatie én het plezier hierin. Door de (aanstaande) ouders al tijdens de zwangerschap kennis en vaardigheden aan te leren, kunnen veel van deze problemen voorkomen worden.</Typography>
                <Typography type='caps' style={{ fontSize: '16pt', color: '#145f68', margin: hasPhone ? '10vw 0 5vw 0' : '2vw 0 0.5vw 0'}}>SAMENWERKEN IN BORSTZORG</Typography>
                <Typography style={{ width: hasPhone ? '90%' : '52%'}}>Goed Aangelegd gelooft dat méér vrouwen succesvol borstvoeding gaan geven met twee pijlers: een hechtere samenwerking tussen lactatiekundigen en verloskundigen en het overdragen van gedegen kennis aan ouders. Om die reden hebben wij een workshopsreeks ontwikkeld die lactatiekundigen kunnen geven aan ouders. Verloskundigen werven de deelnemers voor deze reeks: zij verwijzen koppels in het begin van de zwangerschap naar de workshops die de lactatiekundige vervolgens geeft. Zo wordt de verloskundige zorgverlening ontlast en zorgt de lactatiekundige ervoor dat ouders de benodigde kennis en vaardigheden hebben om succesvol borstvoeding te kunnen geven, nog vóórdat er complicaties opspelen.</Typography>
            </Samenwerken>
            <WatIs ref={watIsRef}>
                <Typography type='h2' style={{ fontSize: hasPhone ? '2.5em' : '2.5em', color: '#114d55', margin: hasPhone ? '0 0 5vw 0' : '0 0 2vw 0' }}>Wat is Goed Aangelegd?</Typography>
                <Typography style={{ width: hasPhone ? '80%' : '52%', margin: hasPhone ? '5vw 0 7vw 0'  : '1vw 0 2vw 0'}}>
                    Goed Aangelegd bestaat uit vier workshops. Onze workshops zijn gebaseerd op wetenschappelijk onderzoek en samengesteld door meer dan 50 professionals. Jij als cursusbegeleidster ontvangt een compleet pakket met alle benodigde tools om zelf de workshops te kunnen geven. Hiermee verrijk je jouw 1-op-1 zorgverlening en kan je bij meer jonge ouders het verschil maken.
                    <br/><br/>
                    <span style={{ fontWeight: '600'}}>Goed Aangelegd is ontworpen voor díe lactatiekundigen die op zoek zijn naar toekomstbestendige borstzorg, waarbij het persoonlijke contact met de (aanstaande) ouders centraal staat.</span>
                </Typography>
                <Button buttonstyle='secondary' onClick={() => ( scrollIntoView(enthousiastRef) )} text='Yes, die lactatiekundige ben ik' style={{ position: 'relative', zIndex: 5 }} />
            </WatIs>
            <Workshops ref={workshopsRef}>
                <TitlePage 
                    color='#be6942'
                    text='Inhoud workshops'
                />
                <Typography type='h1' style={{ color: '#032d34' }}>Wat houden de workshops in?</Typography>
                <div style={{ display: 'flex', flexDirection: hasPhone ? 'column-reverse' : 'row', flexWrap: 'wrap', gap: '2vw', margin: hasPhone ? '7vw 0' : '2vw 0' }}>
                    <div style={{ display: 'flex', flexDirection: hasPhone ? 'row' : 'column', flexWrap: 'wrap'}}>
                        {[workshop1, workshop2, workshop3, workshop4].map((w, i) => (
                            <div style={{ position: 'relative', height: hasPhone ? '40vw' : 'clamp(150px, 16vw, 180px)', width: hasPhone ? '40vw' : 'clamp(170px, 17vw, 180px)' }} key={i}>
                                <img src={w} style={{ position: 'absolute', width: hasPhone ? '40vw' : 'clamp(100px, 18vw, 200px)', left: '-1vw', top: '-1vw'}} alt={w} />
                            </div>
                        ))}
                    </div>
                    <div style={{ width: hasPhone ? '90%' : 'clamp(500px, 60%, 500px)', margin: '1vw 0 0 0'}}>
                        <Typography>De inhoud van de Goed Aangelegd workshops is zorgvuldig samengesteld op basis van wetenschappelijk onderzoek. Aangesloten lactatiekundigen leren (aanstaande) ouders live alles over wat borstvoeding precies is, hoe ze de productie op gang krijgen, hoe ze problemen kunnen voorkomen, en hoe ze de lactatie een plek kunnen geven in hun alledaagse leven.</Typography>
                        <Typography variant='caps' style={{ margin: hasPhone ? '7vw 0 3vw 0' : '2.5vw 0 1vw 0', fontSize: hasPhone ? '1.5em' : '1.2em', color: '#145f68'}}>LIVE EN IN GROEPSVERBAND</Typography>  
                        <Typography>Bij Goed Aangelegd hechten we veel waarde aan een community en veiligheid. Daarom bestaat een groep cursisten uit vier tot zes koppels met een overeenkomend zwangerschapstermijn.
                        Reken er maar niet op dat je een ouderwetse eenzijdige presentatie gaat geven: Goed Aangelegd geeft jou als cursusbegeleider de tools om het zelf nadenken en actief meedoen van de deelnemers te stimuleren.
                        </Typography>
                        <Typography variant='caps' style={{ margin: hasPhone ? '7vw 0 3vw 0' : '2.5vw 0 1vw 0',  fontSize: hasPhone ? '1.5em' : '1.2em', color: '#145f68', textTransform: 'uppercase'}}>Cursusboek en e-learning</Typography>  
                        <Typography>Bij het geven van de workshops wordt je ondersteund door een allesomvattend cursusboek die elk koppel een ontvangt. Hierdoor kan je als lactatiekundige terugvallen op de structuur die dit boek biedt.</Typography><br/>
                        <Typography>Om er o.a. voor te zorgen dat jij als cursusbegeleider ontlast wordt, heeft Goed Aangelegd ook een online platform. Je kan bij verdiepende of verbredende vragen tijdens de workshops cursisten laagdrempelig verwijzen naar hun account waar ze toegang hebben tot meer dan 60 aanvullende modules over o.a. (prenataal) kolven, zwangerschapssuiker en de introductie van vaste voeding worden besproken.</Typography>
                        <Button buttonstyle='sixthly' target='_blank' href={brochure} download='brochure-Goed-Aangelegd.pdf' text='Meer weten? Download de brochure' style={{ position: 'relative', zIndex: 5, margin: hasPhone ? '7vw 0' : '2vw 0 0 0' }} />    
                    </div>
                </div>
            </Workshops>
            <Kosten>
                <Typography type='h1' style={{ color: '#114d55', margin: hasPhone ? '0 0 10vw 0' : '0 0 3vw 0' }}>Kosten en opbrengsten</Typography>
                <Typography style={{ width: hasPhone ? '90%' : '50%'}}>
                Het aanbieden van workshops is een lucratief en duurzaam verdienmodel voor jou als lactatiekundige. Onze workshops zijn kant-en-klaar, en worden door ons up-to-date gehouden. Zo kun jij je volledig richten op het geven van de allerbeste borstvoedingszorg.
                </Typography><br/>
                <Typography style={{ width: hasPhone ? '90%' : '50%', margin: hasPhone ? '0 0 7vw 0' : '0 0 2vw 0'}}>
                De kosten betreffen eenmalige investeringen (training en workshopspakket) en doorlopende investeringen (cursusboeken en abonnement). De aanmeldkosten die de koppels betalen, die zijn volledig voor jou. Afhankelijk van de zorgverzekering wordt die volledig vergoed.
                </Typography>
                <Button text='BEREKEN MIJN INVESTERING EN OPBRENGSTEN' to='/kosten' buttonstyle='fourthly' />
            </Kosten>
            <Kracht>
                <TitlePage 
                    color='#be6942'
                    text='Kracht'
                />
                <Image borderColor='#be6942' src={kracht} objectFit='cover' width='30vw' height={krachtCRef.current?.clientHeight} />
                <div style={{ width: hasPhone ? '80%' : '45%'}} ref={krachtCRef}>
                    <Typography type='h1' style={{ color: '#032d34' }}>Kracht</Typography>
                    <Typography style={{ margin: hasPhone ? '7vw 0 7vw 0' : '2vw 0 1.5vw 0'}}>
                        <div style={{ paddingBottom: hasPhone ? '3vw' : '0.5vw' }}>Onderscheidende kenmerken volgens deelnemers én experts:</div>
                        <List>
                            <ListItem>De lactatiekundige krijgt een standaard rol binnen de geboortezorg;</ListItem>
                            <ListItem>Continuïteit aan borstvoedingszorg gedurende zwangerschap en lactatie;</ListItem>
                            <ListItem>Kant-en-klaar pakket: een gestructureerde en planbare dienstverlening;</ListItem>
                            <ListItem>Bevat professionele en geactualiseerde content;</ListItem>
                            <ListItem>Doorlopende ondersteuning en promotie door Goed Aangelegd;</ListItem>
                            <ListItem>Interactieve groepszorg met een preventief karakter;</ListItem>
                            <ListItem>Een interessant financieel vooruitzicht;</ListItem>
                            <ListItem>Zorgt voor klantenwerving voor jouw individuele zorgverlening.</ListItem>
                        </List>
                    </Typography>
                    <Button align='flex-start' buttonstyle='secondary' style={{ textAlign: 'left', width: '85%' }} text='klinkt dit goed? plan dan direct een gesprek in voor je vragen, eventuele twijfels en aanmelding' onClick={() => ( scrollIntoView(benieuwdRef) )} />
                </div>
            </Kracht>
            <Enthousiast ref={enthousiastRef}>
                <EnthousiastCont id='enthousiast'>
                    <Typography type='h1' style={{ color: '#032d34', margin: hasPhone ? '0 0 7vw 0' : '0 0 2vw 0'}}>Enthousiast?</Typography>
                    <Typography>
                        Wil jij impactvolle zorg geven door voedingsproblemen een stap voor te zijn? Heb je behoefte aan persoonlijk en lokaal contact met je klanten? En durf jij de centrale rol in te nemen binnen de geboortezorg?
                    </Typography>
                    <Typography>
                        Yes? Vraag dan direct onze brochure aan waarin we je uitgebreid vertellen over: hoe een samenwerking met Goed Aangelegd er precies uit gaat zien, de precieze inhoud van de workshops, en wat het jou kan opleveren. 
                    </Typography>
                    <Button text='Vraag de brochure aan' buttonstyle='primary' target='_blank' href={brochure} download='brochure-Goed-Aangelegd.pdf' style={{ margin: hasPhone ? '3vw 0 5vw 0' : '0.5vw 0 2vw 0'}} />
                    <Typography>
                        Plan daarnaast eenvoudig een vrijblijvend en persoonlijk gesprek in via onderstaande online agenda. Dit gesprek gaat altijd voorafgaand aan de definitieve aanmelding.
                    </Typography>
                    <Button text='Plan een gesprek in' buttonstyle='primary' href='mailto:goed.aangelegd@gmail.com' style={{ margin: hasPhone ? '3vw 0 5vw 0' : '0.5vw 0 0vw 0'}} />
                </EnthousiastCont>
                <PDFViewer file={brochure} />
            </Enthousiast>
            {/* <PDFViewer file={brochure} /> */}
            {false && <Ervaringen category='lactatiekundige' />}
            {false && <Veelgestelde category='lactatiekundige' />}
            {false && <Mogelijk />}
            {false && <BlogEnPodcast category='lactatiekundige' />}
            <Benieuwd givenRef={benieuwdRef} category='lactatiekundige' />
            <BrochureModal 
                openModal={openModal}
                setOpenModal={setOpenModal}
                category='lactatiekundige' 
                title='Vraag brochure aan'
                buttonText='Aanvragen'
            />
        </Container>
    );
}