import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    background-color: #e9f1ed;
    padding: 5vw 0vw;
    position: relative;
    overflow: hidden;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vw;

    @media screen and (max-width: 766px) {
        padding: 15vw 0;
        flex-wrap: wrap;
        gap: 7vw;
        overflow: visible;
        text-align: center;
        gap: 10vw;
    }
`;