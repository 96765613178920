import styled from "styled-components";

export const Container = styled.div`
    background-color: #fdfcfa;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw 0;
    width: 100%;
    position: relative;
    gap: 1vw;
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        gap: 5vw;
    }
`;


export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};
`;