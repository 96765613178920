import React, { useState, useEffect } from 'react';

import axiosInstance from '../../actions/axios';

import { Content, InstaImage } from './styled';
import { Typography, Link, ReactIcon } from '..';
import { GeneralContext } from '../../App';

export default function TopFooter(props) {
    const { hasPhone, hasTablet, API } = React.useContext(GeneralContext);

    const [ data, setData ] = useState([]);

    // useEffect(() => {
    //     if (data.length === 0) {
    //         axiosInstance.get('instagram/')
    //             .then(response => {
    //                 let photos = response['data'].photos.replaceAll( "'", '"')
    //                 photos = JSON.parse(photos);
    //                 response['data']['photos'] = photos;
    //                 setData(response['data']['photos']);
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             })
    //     }
    // });

    return (
        <Content>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: hasPhone && '1vw'}}>
                <Typography style={{ fontWeight: 'bold'}}>Goed aangelegd</Typography>
                <Link to='/over'>Over</Link>
                <Link hashLink to='/ouders#aanmelden'>Contact als ouder</Link>
                <a style={{ textDecoration: 'none', color: 'inherit' }} href='mailto:goed.aangelegd@gmail.com' >Contact als lactatiekundige</a>
                <a style={{ textDecoration: 'none', color: 'inherit' }} href='mailto:goed.aangelegd@gmail.com'>Contact als verloskundige</a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: hasTablet ? '2vw' : '1vw', flexWrap: 'wrap', width: hasPhone && '75%'}}>
                <div style={{ display: 'flex', gap: hasPhone ? '3vw' : '1vw', justifyContent: 'center', flexWrap: 'wrap', margin: hasPhone && '0 0 3vw 0'}}>
                    {Array.isArray(data) &&
                     (data.length !== 0 && data.slice(0,4).map((img, i) => {
                        if (img.media_type === 'IMAGE' || img.media_type === 'CAROUSEL_ALBUM') {
                            return <InstaImage key={i} src={img.media_url} onClick={() => window.open(img.permalink, '_blank')}/>
                        } else if (img.media_type === 'VIDEO') {
                            return <InstaImage key={i} src={img.thumbnail_url} onClick={() => window.open(img.permalink, '_blank')}/>
                        }
                        return null;
                    }))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: hasPhone ? '2vw' : hasTablet ? '1vw' : '0.5vw'}}>
                    <ReactIcon name='instagram' />
                    <ReactIcon name='linkedin' />
                    <ReactIcon name='youtube' />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: hasPhone && '1vw'}}>
                <Typography style={{ fontWeight: 'bold'}}>Direct naar</Typography>
                <Link to='/algemene-voorwaarden'>Algemene voorwaarden</Link>
                <Link to='/klachtenregeling'>Klachtenregeling</Link>
                <Link to='/disclaimer-en-privacy'>Disclaimer en privacy</Link>
                <Link to='/vragen'>Veelgestelde vragen</Link>
            </div>
        </Content>
    )
}