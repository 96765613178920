import React, { useRef, useState, useEffect } from 'react';

import { GeneralContext } from '../../App';

import { Container, Introduction, IntroductionText, Zwanger, Background, HulpVoeden, Ontdek, Planning, Workshops, Goededoel, Circle } from './styled';
import { Typography, Video, Image, Sticker, List, ListItem, Button, TitlePage, TextToSpeech, BoldLink, Accordion } from '../../components';
import { Ervaringen, Mogelijk, BlogEnPodcast, Benieuwd, Veelgestelde } from '../../subpages';
import { scrollIntoView } from '../../helpers/ScrollToTop';

import Aanmelden from './Aanmelden';

import ouders from '../../assets/images/sticker-ouders.png';
import voeden from '../../assets/images/ouders/voeden.jpg';
import ontdek from '../../assets/images/ouders/ontdek.png';

import workshop1 from '../../assets/images/workshops/workshop2.png';
import workshop2 from '../../assets/images/workshops/workshop3.png';
import workshop3 from '../../assets/images/workshops/workshop4.png';
// import workshop4 from '../../assets/images/workshops/workshop4.png';
import elearning from '../../assets/images/workshops/elearning.png';
import sponsoring from '../../assets/images/home/sponsoring.png';
import illustratiesponsoring from '../../assets/images/home/illustratie-sponsoring.png';

import background1 from '../../assets/backgrounds/ouders1.png';
import background2 from '../../assets/backgrounds/ouders2.png';
import KostenTable from './KostenTable';

const workshopList = [
    {
        name: 'CENTERING FEEDING 1',
        period: 'zwangerschap',
        list: ['Praktische voedingstechnieken met hands-on demonstraties', 'Het geheim van succesvol voeden','Harmonie in het combineren van borst- en flesvoeding'],
        extraList: ['Perfecte start', 'Jouw geboorteplan', 'Flexibel voeding', 'Bijvoeding', 'Varianten in voeding', 'Kolven en melkopslag', 'Hechting die blijft'],
        extraDescription: ['Praktische tips voor het aanleggen en ideale houdingen bij borst- en flesvoeding.', 'Ontdek hoe joew wensen voor de geboorte perfect aansluiten op een succesvolle voedingservaring.', 'Op verzoek of volgens schema bij zowel borst als fles - wat past het beste bij jou en je baby?', 'Leer alles over de juiste aanpak, timing en keuzes om voedingsproblemen te voorkomen.', 'Ontdek wanneer de borst de beste keuze is en wanneer de fles de juiste optie biedt.', 'Alles wat je moet weten over kolven, inclusief handige tips voor de opslag van moedermelk en behoud van een constante voorraad.', 'Leer hoe je een sterke emotionele band kunt opbouwen met je baby.'],
        image: workshop1,
    },
    {
        name: 'CENTERING FEEDING 2',
        period: 'zwangerschap',
        list: [`Do's and dont's voor aanschaf materialen`, 'Problemen voorkomen, herkennen en aanpakken', 'Dromen versus realiteit: Wanneer hulp inschakelen?'],
        extraList: ['Speengebruik dilemma', 'Tepelhoedjes', 'Borstbumps: stuwing, tepelkloven en ontstekingen', 'Kalmte bij krampen en spugen', 'Onverwachte wendingen: voeden na een inleiding of keizersnede', 'Partner als je rots in de branding', 'Extra zorg'],
        extraDescription: ['Leer de juiste keuzes te maken voor een kalme en tevreden baby.', 'Ze worden vaak snel tevoorschijn gehaald in ziekenhuizen. Leer of, wanneer en hoe je ze het beste kunt inzetten.', 'Alles wat je moet weten om ongemakken te vermijden.', 'Praktische tips voor het omgaan met een onrustige baby door darmkrampen of reflux.', 'Ontdek hoe je vol vertrouwen kunt voeden, zelfs als de start anders verloopt dan gepland.', 'Leer hoe jouw partner een onmisbare rol kan spelen bij het voorkomen en aanpakken van voedingsproblemen.', 'Ontvang handige checklists voor verschillende situaties na de bevalling, zodat je precies weet wanneer en welke hulp in te schakelen.'],
        image: workshop2,
    },
    {
        name: 'CENTERING FEEDING 3',
        period: 'kraamperiode',
        list: ['Terug naar werk en blijven voeden', 'Vaste voeding ontdekken', 'Jezelf, gezin en werk in evenwicht'],
        extraList: ['Inzoomen op huidige zorgen', 'Ouderlijke vermoeidheid', 'Slaap- en voedingsrituel', 'Veilig slapen', 'Groeipatroon en ontwikkeling', 'Flesweigering voorkomen', 'Afbouwen'],
        extraDescription: ['Breng in, ontdek en overwin de huidige problemen, zoals pijn bij het voeden.', 'Creëer samen een aanpak voor de gebroken nachten.', 'Ontdek strategieën om het slaappatroon van je baby te verbeteren en meester te worden in nachtvoedingen.', 'Leer essentiële tips zodat je baby veilig en comfortabel de nacht doorkomt.', 'Leer groeicurves interpreteren en bepaal of afwijkingen reden tot zorg zijn.', 'Faciliteer een vlotte overgang naar flesvoeding en ontdek wanneer je kunt beginnen met oefenen.', 'Leer met grip en zorg borst- en kunstvoeding af te bouwen.'],
        image: workshop3,
    },
    {
        name: 'ONLINE SUPPORT',
        period: 'continu',
        introduction: `Het voeden van je baby beslaat veel meer dan de topics uit de fysieke bijeenkomsten. Daarom vind je in de online omgeving aanvullende video's over:`,
        list: ['Ontcijfer babytaal',
        'Huilgedrag begrijpen',
        'Seksualiteit, en nog veel meer.',],
        extraList: [`Moeder’s leefstijl in balans`, 'Terug sporten', 'Hormonale rollercoaster', 'Relaties in de spotlicht', 'Voeden buitenshuis', 'Babyrecepten', 'Gezinsuitbreiding'],
        extraDescription: ['Verken hoe jouw levensstijl de borstvoeding en het gedrag van je baby beïnvloed.', 'Verken de invloed van sport op voeding en ontdek hoe je ze succesvol kunt combineren.', 'Verken de wispelturige wereld van vrouwenhormonen. Weet wat je kunt verwachten en wat als normaal wordt beschouwd (en wat niet).', 'Met je baby, andere kinderen, je partner en niet te vergeten, met jezelf.', 'De kunst van het openbaar voeden en je zelfverzekerd voelen, waar je ook bent.', 'Ontdek de ideale babyrecepten voor de avontuurlijke fase van vaste voeding.', 'Overweeg je een nieuwe baby of wacht je nog even? Raadpleeg de keuzegids voor anticonceptie.'],
        image: elearning,
    },

]

const pageText = [
    { type: 'title', text: [`Samen maken we van\n`, `jullie borstvoedingsavontuur\n`, `een succes`] },
    { type: 'title', text: 'Yes, zwanger!' },
    { type: 'paragraph', text: `Grote veranderingen, wisselende emoties en nieuwe ervaringen staan op je te wachten. Je lichaam is hard aan het werk om een mini-mens te maken. Nog ‘eventjes’ en dan ligt hij of zij in jouw armen. Dan ben je zelf aan zet om dit kindje te laten groeien en bloeien. Geweldig! Maar... hoe dan?` },
    { type: 'subtitle', text: `Het voeden voorbereiden` },
    { type: 'paragraph', text: `Door al tijdens de zwangerschap een goede basiskennis te krijgen over het voeden, houd je na de geboorte meer tijd en energie over om te genieten van je pasgeboren baby. Een goede voorbereiding voorkomt zorgen, stress en problemen. Dat maakt het juist zo belangrijk. De workshops van Goed Aangelegd zijn er om jullie hierbij te helpen.`},
    { type: 'title', text: 'Hulp bij het voeden'},
    { type: 'paragraph', text: 'Ontvang alles wat je moet weten en kunnen om je baby goed te voeden, zodat je:' },
    { type: 'list', text: ['Een optimale hechting bereikt;', 'Geen twijfels hebt over je melkproductie;', 'Pijnloos kunt voeden;', 'Problemen voorkomt.'] },
    { type: 'paragraph', text: 'Je kunt de workshops volgen bij een aangesloten lactatiekundige in de buurt. Zo leg je samen de basis voor een succesvolle borstvoeding.'},
    { type: 'title', text: 'ontdek'},
    { type: 'list', text: ['Hoe moet ik mijn baby voeden? Basisprincipes voor borst- en flesvoeding?', 'Hoe vaak en hoelang moet ik voeden? Hoeveel drinkt een baby dan?', 'Hoe weet ik of mijn borsten genoeg melk produceren?', 'Wat als het voeden niet lukt? Hoe los ik problemen op?', 'Doet borstvoeding pijn? Hoe maak ik het comfortabeler?', 'Hoe voorkom ik borstontstekingen en wat als ze toch optreden?', 'Hoe betrekt mijn partner zich bij het voeden, vooral bij borstvoeding?', 'Hoe combineer ik borstvoeding met werk? Kan dit deels met kunstvoeding?', 'Wat zijn de beste voedingstechnieken en -houdingen voor borst- en flesvoeding?', 'Welke materialen zijn nodig? Tips voor gebruik?']},
    { type: 'title', text: 'Planning' },
    { type: 'paragraph', text: `Goed Aangelegd biedt drie 'CENTERING FEEDING' workshops aan. Alle onderdelen sluiten naadloos op elkaar aan, dus het is ons advies om de complete reeks te volgen.`},
    { type: 'list', text: [workshopList[0].name + ': ' + workshopList[0].period, workshopList[1].name + ': ' + workshopList[1].period, workshopList[2].name + ': ' + workshopList[2].period]},
    { type: 'paragraph', text: 'De workshops worden gegeven door een aangesloten lactatiekundige. Neem eenvoudig contact op met een voedingsexpert bij jullie in de buurt en bekijk welke datums voor jullie beschikbaar zijn.' },
    { type: 'title', text: 'Wat houden de workshops in?' },
    { type: 'paragraph', text: 'Je krijgt volledige ondersteuning van jouw lactatiekundige en medemoeders om een positieve voedingservaring met je kindje te creëren. Goed Aangelegd biedt jullie hierbij alle benodigde tools door middel van praktische workshops en online videosupport.'},
    { type: 'list', text: ['JE WEET... Wat je te wachten staat;', 'JE WEET... Hoe je met uitdagingen om moet gaan;', 'JE WEET... Hoe je onnodige struikelpunten voor kunt zijn.']},
    { type: 'subtitle', text: workshopList[0].name },
    { type: 'list', text: workshopList[0].list },
    { type: 'subtitle', text: workshopList[1].name },
    { type: 'list', text: workshopList[1].list },
    { type: 'subtitle', text: workshopList[2].name },
    { type: 'list', text: workshopList[2].list },
    { type: 'subtitle', text: workshopList[3].name },
    { type: 'list', text: workshopList[3].list },
    { type: 'title', text: 'Kosten en pakketopties'}
]



export default function Ouders(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ imageHeight, setImageHeight ] = useState(null);
    

    const introductionRef = useRef(null);
    const benieuwdRef = useRef(null);
    const zwangerRef = useRef(null);
    const voedingsRef = useRef(null);
    const aanmeldenRef = useRef(null);

    const feeding1Ref = useRef(null);
    const feeding2Ref = useRef(null);
    const feeding3Ref = useRef(null);
    const onlineRef = useRef(null);
    const workshopRefs = [feeding1Ref, feeding2Ref, feeding3Ref, onlineRef];

    useEffect(() => {
        if (!imageHeight) {
            // we manually compute the height because flex already compensates for it automatically
            let boxChildNodes = voedingsRef.current.childNodes;
            let firstChildTop = boxChildNodes[0].getBoundingClientRect().top;
            let lastChildTop = boxChildNodes[boxChildNodes.length - 1].getBoundingClientRect().top;
            setImageHeight(lastChildTop - firstChildTop);
        }

        
    }, [imageHeight])

    return (
        <Container id='container'>
            {!hasPhone && <Background src={background1} zIndex='3' top='0' left={'0'} height={introductionRef.current?.clientHeight + zwangerRef.current?.clientHeight*0.7} />}
            <Sticker src={ouders} position='fixed' bottom='1vh' width='clamp(150px, 10vw, 10vw)' hover={true} onClick={() => scrollIntoView(aanmeldenRef) } />
            <Introduction ref={introductionRef}>
                <div style={{ zIndex: '5'}}>
                    <Video videoId='XyrdxTmWTBA' width={hasTablet ? '40vw' : '50vw'} height={hasTablet ? '25vw' : '30vw'} />
                </div>
                <IntroductionText>
                    <TextToSpeech textList={pageText}/>
                    <Typography type='h1' style={{ color: '#be6942', margin: '2vw 0 2vw 0', width: hasPhone && '90vw', fontSize: hasPhone ? '1.5em' : hasTablet ? '1.6em' : '2em', whiteSpace: 'pre' }}>{pageText[0].text}</Typography>
                    <Button 
                        text='ZOEK HIER JOUW VOEDINGSEXPERT'
                        onClick={() => scrollIntoView(aanmeldenRef)}
                    />
                </IntroductionText>
            </Introduction>
            <Zwanger ref={zwangerRef}>
                <TitlePage 
                    text='Goed aangelegd'
                    color='#92330f'
                />
                <Typography type='h2' style={{ fontSize: hasPhone ? '2em' : hasTablet ? '2em' : '3em', color: '#92330f' }}>{pageText[1].text}</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '2vw 0 2vw 0', fontSize: hasPhone && '14px' }}>
                    <Typography style={{ width: hasPhone ? '85%' : hasTablet ? '60%' : '45%' }}>
                        {pageText[2].text}
                    </Typography><br/>
                    <Typography type='caps' style={{ color: '#145f68', fontSize: hasPhone && '16px', margin: hasPhone ? '1vw 0 1vw 0' : '1vw 0 0.5vw 0' }}>{pageText[3].text}</Typography>
                    <Typography style={{ width: hasPhone ? '85%' : hasTablet ? '60%' : '45%' }}>
                        {pageText[4].text}
                    </Typography>
                </div>
                <BoldLink
                    text='ZOEK HIER EEN WORKSHOPLOCATIE BIJ JOU IN DE BUURT'
                    onClick={() => scrollIntoView(aanmeldenRef)}
                    style={{ fontSize: '1em', maxWidth: hasPhone && '90%', textAlign: hasPhone && 'center' }}
                />
            </Zwanger>
            <HulpVoeden>
                <TitlePage 
                    text='Workshops'
                    color='#92330f'
                />
                <div ref={voedingsRef} style={{ display: 'flex', flexDirection: 'column', gap: '2.5vw', width: hasPhone ? '80%' : '40%'}}>
                    <Typography type='h2' style={{ fontSize: hasPhone ? '1.8em' : hasTablet ? '2em' : '2.5em', margin: hasPhone && '0 0 2vw 0'}}>{pageText[5].text}</Typography>
                    <Typography>
                        {pageText[6].text}
                    </Typography>
                    <List>
                        {pageText[7].text.map((item, i) => (
                            <ListItem key={i}>{item}</ListItem>
                        ))}
                    </List>
                    <Typography>
                        {pageText[8].text}
                    </Typography>
                    <Button 
                        text='Zoek hier jouw lactatiekundige'
                        onClick={() => scrollIntoView(aanmeldenRef)}
                        buttonstyle='secondary'
                    />
                </div>
                {imageHeight && <Image src={voeden} height={hasPhone ? '80vw' : (imageHeight || '20vw')} width={hasPhone ? '80vw' : hasTablet ? '30vw' : '25vw'} objectFit={'cover'} />}
            </HulpVoeden>
            <Ontdek>
                <TitlePage 
                    text='ontdek'
                    color='#92330f'
                />
                <Typography type='caps' style={{ fontSize: hasPhone ? '1.2em' : hasTablet ? '1.3em' : '1.5em', color: '#145F68', margin: '0 0 1vw 0' }}>{pageText[9].text}</Typography>
                <List style={{ width: hasPhone ? '80vw' : '50vw', margin: hasPhone ? '8vw 0 5vw 0' : hasTablet ? '0 0 5vw 0' : '0 0 2vw 0' }}>
                    {pageText[10].text.map((item, i) => (
                            <ListItem key={i}>{item}</ListItem>
                    ))}
                </List>
                <BoldLink
                    text='ZOEK HIER JOUW LACTATIEKUNDIGE'
                    onClick={() => scrollIntoView(aanmeldenRef)}
                    style={{ fontSize: '1em'}}
                />
                {!hasPhone && <Image src={ontdek} height='70%' style={{ position: 'absolute', right: hasTablet ? '0' : '15vw', bottom: '0', border: 'none' }} />}
            </Ontdek>
            <Aanmelden 
                parentRef={aanmeldenRef} 
                category='ouders' 
                title='Zoek contact'
                subtitle='voor aanmelding, data en info'
            />
            <Workshops>
                <TitlePage 
                    text='Inhoud workshops'
                    color='#92330f'
                />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw', position: 'relative' }}>
                    <Typography type='h2' style={{ color: '#466166', fontSize: hasPhone ? '1.8em' : '2.5em', margin: hasPhone ? '0 0 5vw 0' : '0 0 2vw 0'}}>{pageText[15].text}</Typography>
                    <Typography style={{ width: hasPhone ? '80vw' : '45%' }}>{pageText[16].text}</Typography>
                    <List style={{ margin: hasPhone ? '0 0 5vw 0' : '0 0 2vw 0'}}>
                        {pageText[17].text.map((item, i) => (
                            <ListItem key={i}>{item}</ListItem>
                        ))}
                    </List>
                    {workshopList.map((workshop, i) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: hasPhone && 'center', flexDirection: hasPhone && 'row-reverse', gap: '2vw', margin: '0.5vw 0' }} key={i} ref={workshopRefs[i]}>
                            <Image src={workshop.image} style={{ height: hasPhone ? '75vw' : hasTablet ? '130px' : '150px', width: hasPhone ? '75vw' : hasTablet ? '130px' : '150px', border: 'none'}} />
                            <div>
                                <Typography type='caps' style={{ color: 'var(--trust)', fontSize: '1.2em', width: hasPhone ? '80vw' : '75%', margin: '0 0 1vw 0'}}>{workshop.name}</Typography>
                                {workshop.introduction && <Typography style={{ width: hasPhone ? '80vw' : hasTablet ? '60vw' :  '75%', margin: '0 0 0.5vw 0'}}>{workshop.introduction}</Typography>}
                                <List>
                                    {workshop.list.map((item, j) => (
                                        <ListItem key={j}>{item}</ListItem>
                                    ))}
                                </List>
                                <Accordion
                                    headertext='LEES MEER'
                                    headercolor='#145f68'
                                    align={'flex-start'}
                                >
                                    <div style={{ width: !hasPhone && '60%'}}>
                                        <Typography style={{ margin: '0 0 0.5vw 0'}}>Enkele onderwerpen:</Typography>
                                        <List>
                                            {workshop.extraList && workshop.extraDescription &&  workshop.extraList.map((item2, k) => (
                                                <ListItem key={k}><span style={{ fontWeight: '600'}}>{item2}:</span> {workshop.extraDescription[k]}</ListItem>
                                            ))}
                                        </List>
                                    </div>
                                </Accordion>
                                {i === workshopRefs.length - 1 && 
                                    <Button 
                                        text='ZOEK HIER JOUW LACTATIEKUNDIGE'
                                        onClick={() => scrollIntoView(aanmeldenRef)}
                                        style={{ maxWidth: hasPhone ? '100%' :'75%', margin: hasPhone ? '5vw 0 0 0' : '1.5vw 0 0 0'}}
                                        buttonstyle='sixthly'
                                />}
                            </div>
                        </div>
                    ))}
                </div>
            </Workshops>
            <KostenTable 
                workshopList={workshopList}
                pageText={pageText}
                linkAanmelden={() => scrollIntoView(aanmeldenRef)}
            />
            <Goededoel>
                <TitlePage 
                    text='sponsoring'
                    color='#92330f'
                />
                {!hasPhone && !hasTablet && <Image src={illustratiesponsoring} width={hasPhone ? '40vw' : hasTablet ? '22vw' : '29vw'} height={hasPhone ? '50vw' : hasTablet ? '25vw' : '35vw'} style={{ marginLeft: '2vw', border: 'none', zIndex: hasPhone ? '1' : '3', position: hasPhone ? 'absolute' : 'relative', bottom: '0', left: '0', maxHeight: '500px', opacity: hasPhone && 0.2 }} />}
                <div style={{ width: hasPhone ? '85vw' : hasTablet ? '85vw' : '50vw', margin: '0 0 0 0', padding: !hasPhone && '5vw 0vw'}}>
                    <Typography type='caps' style={{ fontSize: hasPhone ? '1em' : '1.5em', color: 'var(--trust)', textAlign: hasPhone && 'center', margin: hasPhone ? '0 0 3vw 0' : '0 0 1vw 0' }}>Bij aanmelding steun je het goede doel</Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: '2vw', margin: hasPhone ? '0 0 5vw 0' : '0 0 1vw 0' }}>
                        <Circle>
                            <Image src={sponsoring} width='80px' height='60px' style={{ objectFit: 'fill', border: 'none' }} />
                        </Circle>
                        <Typography>Bij jou deelname aan de workshops draag je bij aan het prachtige initiatief van Mothers On the Move (MOM). Door de onbereikbare zorg in het ruige West-Kenia is de moeder-kindsterfte daar hoog. MOM zorgt ervoor dat Keniaanse vrouwen wél zorg ontvangen!</Typography>
                    </div>
                    <Typography style={{ margin: hasPhone ? '0 0 1vw 0' : '0 0 0.5vw 0'}}>Bij elke workshopsreeks die start, ontvangt een Keniaanse dame:</Typography>
                    <List>
                        <ListItem>Vervoer naar het ziekenhuis tijdens de bevalling;</ListItem>
                        <ListItem>Een kraampakket, inclusief een klamboe tegen malaria;</ListItem>
                        <ListItem>Eén jaar nazorg met groeicontroles en vaccinaties;</ListItem>
                        <ListItem>Deelname aan maandelijkse focusgroepen;</ListItem>
                        <ListItem>Zaden voor het starten van een groentetuin.</ListItem>
                    </List>
                    <Typography style={{ margin: hasPhone ? '1vw 0 5vw 0' : '0.5vw 0 2vw 0'}}>Wil jij helpen om moeder- en kindersterfte terug te dringen? Maak het verschil voor Keniaanse gezinnen en doe mee aan de workshops van Goed Aangelegd!</Typography>
                    <Button 
                        text='STEUN MOM: VIND HIER DE LACTATIEKUNDIGE UIT JE REGIO EN MELD JE AAN'
                        onClick={() => scrollIntoView(aanmeldenRef)}
                        buttonstyle='secondary'
                    />
                </div>
            </Goededoel>
            
            <Ervaringen category='ouders' />
            {false && <Veelgestelde category='ouders' />}
            <Mogelijk />
            {false && <BlogEnPodcast category='ouders' />}
            <Benieuwd givenRef={benieuwdRef} onClickRef={aanmeldenRef} category='ouders' />
        </Container>
    );
}