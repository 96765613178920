import React from 'react';

import { Button, Typography, SingleCarousel } from '../../components';

import backgroundLeft from '../../assets/backgrounds/GA_Branding_Patroon 7g.png';
import backgroundRight from '../../assets/backgrounds/GA_Branding_Patroon 7h.png';

import { Background, Container } from './styled';
import { GeneralContext } from '../../App';

export default function Ervaringen(props) {
    const { category } = props;
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    let categoryText = 'Van ouders &\n zorgverleners'
    if (category === 'ouders') {
        categoryText = 'Van ouders'
    } else if (category === 'lactatiekundige') {
        categoryText = 'Van lactatiekundigen'
    } else if (category === 'verloskundige') {
        categoryText = 'Van verloskundigen'
    }

    return (
        <Container>
            <Background src={backgroundLeft} top='0' left='0' widthStyle={hasPhone ? '30vw' : '25vw'} />
            <Typography type='h2' style={{ color: '#145f68', fontSize: hasPhone ? '2em' : '2.5em' }}>Ervaringen</Typography>
            <Typography type='caps' style={{ fontSize: hasPhone ? '1.3em' : '22px', color: '#145f68', margin: hasPhone ? '3vw 0 5vw 0' : '0', whiteSpace: 'pre-line' }}>{categoryText}</Typography>
            <SingleCarousel category={props.category} />
            <Background src={backgroundRight} bottom='0' right='0' widthStyle={hasPhone ? '30vw' : '25vw'} />
        </Container>
    )
}