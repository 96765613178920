import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 3vw 0;
    position: relative;
`;

export const Tijdsinvestering = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3vw 0;

    background-color: #FDFCFA;
    gap: 1vw;
`;

export const TableContainer = styled.div`
    border: 1vw solid white;
    background-color: #E9F1ED;
    padding: 1vw;
    position: relative;
`;

export const Table = styled.div`
    display: grid;

    grid-template-columns: repeat(12, 2vw);
    align-items: center;
    justify-content: center;
    text-align: center;
    column-gap: 1vw;
`;

export const TableTextFlex = styled.div`
    display: flex;
    padding: 0.5vw 1vw 0 2.7vw;
    gap: 2vw;
    position: relative;
`;

export const TableText = styled.div`
    // margin: 1vw 0 0 0;
`;

export const Rectangle = styled.div`
    position: absolute;
    border: 2px solid black;
    border-radius: 5%;
    height: 7vw;
    width: 1.8vw;
    bottom: 6.6vw;
    left: 6.9vw;
`;

export const Winst = styled.div`
    background-color: #F8F3EC;
    padding: 3vw 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1vw;
`;