import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    // display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Introduction = styled.div`
    display: flex;
    width: 100%;
    padding: 5vw 0vw;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    gap: 1vw;
    // z-index: 3;
    // position: relative;
    background-color: #f7f3ec;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-wrap: wrap;
        flex-direction: column-reverse; 
        align-items: center;
    }
`;

export const IntroductionText = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 100%;
        gap: 5vw;
        margin-bottom: 10vw;
    }
`;

export const Samenwerken = styled.div`
    background-color: white;
    padding: 5vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 5vw;
    }
`;

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};
`;

export const WatIs = styled.div`
    background-color: #f7f3ec;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }
`;

export const Workshops = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: 10vw;
    background-color: #fdfcfa;
    position: relative;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 10vw;
    }

    @media screen and (min-width: 1440px) {
        padding-left: 15vw;
    }
`;

export const Kosten = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f6efe7;
    padding: 5vw;
    z-index: 4;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 10vw;
    }
`;

export const Kracht = styled.div`
    position: relative;
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: 5vw;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8vw;

    background-color: #fdfcfa;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
`;

export const Enthousiast = styled.div`
    padding: 5vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 7vw;

    background-color: #f1e3d4;

    @media screen and (max-width: 1024px) {
        padding: 5vw 0 10vw 0;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-direction: column;
        flex-wrap: wrap;
    }
`;

export const EnthousiastCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.7vw;
    width: 35%;
    margin-left: 3vw;

    @media screen and (max-width: 1024px){
        gap: 1vw;
        width: 60vw;
        margin-left: 0;
        text-align: center;
        align-items: center;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 80%;
    }
`;

export const ModalBox = styled.div`
    position: absolute;
    left: 40%;
    top: 30%;
    width: 24vw;
    height: 24vw;
    background-color: white;
    box-shadow: 24;

    padding: 1vw;
    display: flex;
    flex-direction: column;

    border: solid 1px white;
    border-radius: 1%;
`