import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    overflow: hidden;
`;

export const Introduction = styled.div`
    display: flex;
    width: 100%;
    padding: 5vw 0vw;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    gap: 2vw;
    background-color: #f7f3ec;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-wrap: wrap;
        flex-direction: column-reverse; 
        align-items: center;
    }
`;

export const IntroductionText = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 100%;
        gap: 5vw;
        margin-bottom: 10vw;
    }
`;

export const Borstzorg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fdfcfa;
    padding: 5vw 0;
    gap: 2vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }
`;

export const GoedAangelegd = styled.div`
    background-color: #f7f3ec;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5vw 0;
    flex-direction: column;
    text-align: center;
    gap: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        gap: 7vw;
    }
`;

export const HoeWerktHet = styled.div`
    padding: 5vw 0;
    display: flex;
    position: relative;
    justify-content: center;
    // align-items: center;
    gap: 7vw;
    background-color: #fdfcfa;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 15vw 0;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;

export const Educatie = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    z-index: 1;
    background-color: #fdfcfa;
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 15vw 0;
    }
`;

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};
`;

export const Samenwerken = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    padding: 5vw 0;
    background-color: #f1e3d4;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 15vw 0;
    }
`;

export const LineClick = styled.a`
    text-decoration: underline;
    color: black;

    &:hover {
        cursor: pointer;
    }
`;