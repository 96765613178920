import React, { useState } from "react"
import { Typography, Sticker, Button, Image } from "../../components";
import { styled } from '@mui/material/styles';
import { Slider, SliderThumb } from "@mui/material";
import { Container, Tijdsinvestering, Winst } from "./styled";

import lactatiekundigen from '../../assets/images/sticker-lactatiekundigen.png';
import { HashLink } from "react-router-hash-link";

import kosten1 from '../../assets/images/kosten/kosten1.png';
import kosten2 from '../../assets/images/kosten/kosten2.png';
import slider from '../../assets/images/kosten/slider.png';

const CustomSlider = styled(Slider)({
    color: '#e7cdb5',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 0,
      width: 24,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
  });

function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <img src={slider} style={{ width: '0.8vw' }}/>
        </SliderThumb>
    );
}

export default function Kosten(props) {
    const [ numWorkshops, setNumWorkshops ] = useState(4);

    const workshopsRange = [...Array(12).keys()];
    const sliderMarks = workshopsRange.map((w, i) => ({ label: (w+1).toString(), value: (w+1)}));

    const calculateTime = (num) => {
        const hours = (3.75*numWorkshops)/4;
        const hoursString = hours.toString();

        let hoursReturn = hoursString;
        let minutesReturn = null;
        if (hoursString.includes('.')) {
            hoursReturn = hoursReturn.split('.')[0];
            const minutesDecimal = Number('0.' + hoursString.split('.')[1]);
            minutesReturn = (minutesDecimal * 60).toString();
            if (minutesReturn.includes('.')) {
                minutesReturn = minutesReturn.split('.')[1];
            }
        }

        let finalString = '';
        if (hoursReturn > 0) {
            finalString = hoursReturn + ' uur'
            if (minutesReturn) {
                finalString += ' en ' + minutesReturn + ' minuten '
            }
        } else {
            finalString += minutesReturn + ' minuten '
        }

        
        return finalString;
    }


    return (
        <Container>
            <HashLink to='/lactatiekundigen#enthousiast' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: 10}}>
                <Sticker src={lactatiekundigen} position='fixed' top='80vh' width='10vw' hover={true} />
            </HashLink>
            <Typography type='h1' style={{ color: '#145F68', margin: '2vw 0' }}>Kosten en opbrengsten</Typography>
            <Typography style={{ width: '30%', textAlign: 'center', margin: '0 0 3vw 0' }}>Het financiële plaatje wordt mede bepaald door het aantal workshopreeksen dat jaarlijks wordt gegeven.</Typography>
            <Tijdsinvestering>
                <Typography type='caps' style={{ fontSize: '1.5em', color: '#145F68' }}>Tijdsinvestering</Typography>
                <Typography>Één workshopreeks omvat de volgende werkuren:</Typography>
                <Image src={kosten1} style={{ height: '8vw'}} />
                <Typography style={{ width: '25%', textAlign: 'center'}}>
                    Je bepaalt zelf het aantal reeksen dat jij per jaar start.<br/>
                    Het streven is om 4 tot 6 koppels met een vergelijkbare uitgerekende datum in te delen in één groep.
                    Je bent zelf verantwoordelijk voor het plannen van de workshops.
                </Typography>
                <Typography style={{ width: '23%', textAlign: 'center'}}>
                    Een jaarrooster kan er bijvoorbeeld als volgt uitzien, <br/>
                    waarbij om de maand een nieuwe reeks van start gaat. <br/>
                    Dit komt neer op een begeleiding van 30 koppels per jaar (6 reeksen x ca. 5 koppels).
                </Typography>
                <Image src={kosten2} style={{ height: '25vw'}} />
            </Tijdsinvestering>
            <Winst>
                <Typography type='h1' style={{ fontSize: '2.5em', color: '#104D55', margin: '1vw 0'}}>Bereken mijn investering en winst</Typography>
                <Typography style={{ width: '23%', textAlign: 'center'}}>
                    De kosten bestaan uit eenmalige (training en workshopspakket) en doorlopende investeringen (cursusboeken en abonnement).
                    De aanmeldkosten die de koppels betalen, zijn volledig voor jou.
                    Afhankelijk van de zorgverzekering worden de kosten voor de cursisten volledig vergoed.
                </Typography>
                <Typography style={{ width: '23%', textAlign: 'center'}}>
                    Het financiële plaatje wordt mede bepaald door het aantal workshopsreeksen dat jaarlijks wordt gegegeven.
                    Vul je gewenste ureninvestering in en reken eenvoudig uit wat jouw tijdsinvestering en winstmarge per jaar gaat zijn:
                </Typography>
                <Typography type='caps' style={{ fontSize: '1.5em', color: '#B04F29', margin: '2vw 0 0 0' }}>Aantal workshops per maand</Typography>
                <CustomSlider
                    value={numWorkshops}
                    slots={{ thumb: AirbnbThumbComponent }}
                    min={1}
                    max={12}
                    step={1}
                    marks={sliderMarks}
                    style={{ width: '25%'}}
                    onChange={(e, v) => setNumWorkshops(v)}
                    // marks={marks}
                />      
                <Typography>Dit komt neer op een totaalinvestering van <span style={{ fontWeight: '600' }}>{calculateTime(numWorkshops)}</span> per week</Typography>
                <Button text='Bereken' onClick={() => console.log('bereken')} />
            </Winst>
        </Container>
    )
}