import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { clarity } from 'react-microsoft-clarity';

import { OudersEvaluatie, Gratis, Podcast,PodcastOuders, PodcastEpisode, Contact, Home, Lactatiekundigen, NotFound, Over, Verloskundigen, Ervaringen, Medewerking, Vragen, Kosten, ContactFormulier, Temporary, MOM } from './pages';
import { BottomFooter, TopFooter, Menu, Modal } from './components';
import { Main, Content } from './styled';
import ScrollToTop from "./helpers/ScrollToTop";

import { isPhone, isTablet } from "./helpers/responsive";
import Ouders from "./pages/Ouders/Ouders";

export const GeneralContext = React.createContext();

function App() {
  const [ hasPhone, setHasPhone ] = useState(isPhone(window.screen));
  const [ hasTablet, setHasTablet ] = useState(isTablet(window.screen));

  useLayoutEffect(() => {
    function updateResponsive() {
      setHasPhone(isPhone(window.screen));
      setHasTablet(isTablet(window.screen));
    }
    window.addEventListener('resize', updateResponsive);
    updateResponsive();
    return () => window.removeEventListener('resize', updateResponsive);
  }, []);

  useEffect(() => {
    clarity.init(process.env.REACT_APP_CLARITY_ID) 
  })

  return (
    <GeneralContext.Provider value={{ 'hasPhone' : hasPhone, 'hasTablet': hasTablet }}>
      <Router>
        <Main>
          <Menu />
          <Content>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path='/ouders' element={<Ouders />} />
              <Route path='/ouders/evaluatie' element={<OudersEvaluatie/>} />
              <Route path='/lactatiekundigen' element={<Lactatiekundigen  />} />
              <Route path='/verloskundigen' element={<Verloskundigen  />} />
              <Route path='/over' element={<Over  />} />
              <Route path='/podcast' element={<Podcast  />} />
              <Route path='/podcast/ouders' element={<PodcastOuders />} />
              <Route path='/podcast/ouders/:podcastId' element={<PodcastEpisode />} />
              <Route path='/contact' element={<Contact  />} />
              <Route path='/ervaringen' element={<Ervaringen  />} />
              <Route path='/vragen' element={<Temporary  />} />
              <Route path='/medewerking' element={<Medewerking  />} />
              <Route path='/kosten' element={<Kosten />} />
              <Route path='/contact-formulier' element={<ContactFormulier />} />
              <Route path='/algemene-voorwaarden' element={<Temporary />} />
              <Route path='/disclaimer-en-privacy' element={<Temporary />} />
              <Route path='/klachtenregeling' element={<Temporary />} />
              <Route path='/MOM' element={<MOM />} />
              <Route path='/gratis' element={<Gratis />} />
              <Route path="*" element={<NotFound  />}/>
            </Routes>
          </Content>
          <TopFooter  />
          <BottomFooter  />
        </Main>
      </Router>
    </GeneralContext.Provider>
  );
}

export default App;
