import React from "react";
import { Link, Icon } from '..';
import { Text, Box, CircleText } from "./styled";
import { buttonStyles } from "./colors";
import { HashLink } from "react-router-hash-link";
import { GeneralContext } from "../../App";

export default function Button(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);
    const [over, setOver] = React.useState(false);

    // determine color style
    const filterButtonStyles = buttonStyles.filter(v => v.name === props.buttonstyle);
    const buttonstyle = filterButtonStyles.length > 0 ? filterButtonStyles[0] : buttonStyles[0];
    const noText = props.text === '';

    const Content = (props) => {
        return (
            <Box 
                ref={props.buttonRef}
                backgrcolor={props.inverseStyle ? buttonstyle.hoverBoxColor : buttonstyle.boxColor}
                color={props.inverseStyle ? buttonstyle.hoverTextColor : buttonstyle.textColor}
                hoverbackgrcolor={props.disabled ? buttonstyle.boxColor : props.inverseStyle ? buttonstyle.boxColor : buttonstyle.hoverBoxColor}
                hovercolor={props.disabled ? buttonstyle.textColor : props.inverseStyle ? buttonstyle.textColor : buttonstyle.hoverTextColor}
                onMouseEnter={() => setOver(true)}
                onMouseLeave={() => setOver(false)}
                noText={noText}
                align={props.align}
                circle={props.circle}
                {...props}
            >
                {props.iconName && !props.iconRight &&
                    <Icon name={props.iconName} color={(over && !hasPhone && !hasTablet) ? buttonstyle.hoverTextColor : buttonstyle.textColor} {...props.iconProps} />
                }
                {props.children ? props.children : <Text>{props.text}</Text>}
                {props.iconName && props.iconRight &&
                    <Icon name={props.iconName} color={(over && !hasPhone && !hasTablet)  ? buttonstyle.hoverTextColor : buttonstyle.textColor} {...props.iconProps} />
                }
            </Box>
        )
    }

    if (props.type === 'submit') {
        return (
            <div as='input' onClick={props.onClick}>
                <Content {...props} />
            </div>
        )
    } else if (props.onClick) {
        return (
            <div onClick={props.onClick}>
                <Content {...props} />
            </div>
        )
    } else if (props.hashTo) {
        return (
            <HashLink smooth to={props.hashTo} style={{ textDecoration: 'none' }} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                <Content {...props} />
            </HashLink>
        )
    } else if (props.to) {
        return (
            <Link to={props.to}>
                <Content {...props} />
            </Link>
        )
    } else if (props.href) {
        return (
            <a href={props.href} {...props} style={{ textDecoration: 'none' }} >
                <Content {...props} />
            </a>
        )
    } else if (props.type) {
        return <Content {...props} />
    }

    return null;
}